import React, { useState } from 'react'
import { Avatar, Button, Input, List, Modal } from 'antd';
import { Get_Companies_Details } from '../../redux/SuperAdmin/SuperAdminActions';
import { useDispatch, useSelector } from 'react-redux';


const UserList = ({ companiesDetails }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const usertoken = useSelector((state) => state.Auth.token);
    const dispatch = useDispatch();

    const handleEdit = (user) => {
        setCurrentUser(user);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentUser(null);
    };

    // const handleSave = () => {
    //     console.log('Updated User:', currentUser);
    //     setIsModalVisible(false);
    // };

    // const handleSave = () => {
    //     if (currentUser) {
    //         dispatch(Get_Companies_Details(currentUser, usertoken))
    //         console.log('Updated User:', currentUser);
    //     }
    //     setIsModalVisible(false);

    // };
    const handleSave = () => {
        if (currentUser) {
            console.log("Saving updated user:", currentUser);

            const data = {
                action: "update",
                userId: currentUser._id,
                userData: currentUser
            };
            // dispatch(Get_Companies_Details(data, usertoken));

            // Close the modal
            setIsModalVisible(false);
        }
    };
    return (
        <div className='pagiTable'>
            <h2>User List</h2>
            <List
                itemLayout="horizontal"
                dataSource={companiesDetails?.users || []}
                style={{ height: '276px', overflowY: 'scroll' }}
                renderItem={(user, index) => (
                    <List.Item
                        style={{ padding: '9px 0px', alignItems: 'center' }}
                        extra={
                            <button
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: '#000',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                }}
                                onClick={() => handleEdit(user)}
                            >
                                Edit
                            </button>
                        }
                    >
                        <List.Item.Meta
                            className="usersList"
                            avatar={
                                <Avatar
                                    style={{
                                        backgroundColor: '#87d068',
                                        height: '40px',
                                        width: '40px',
                                    }}
                                >
                                    {user.name ? user.name.charAt(0).toUpperCase() : ''}
                                </Avatar>
                            }
                            title={<p>{user.name}</p>}
                            description={<p>{user.email || 'No email available'}</p>}
                        />
                    </List.Item>
                )}
            />

            <Modal
                title="Edit User"
                visible={isModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                okText="Save"
            >
                <div style={{ marginBottom: '16px' }}>
                    <label>Name:</label>
                    <Input
                        value={currentUser?.name || ''}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }))
                        }
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <Input
                        value={currentUser?.email || ''}

                    />
                </div>
                <div style={{ marginBottom: '16px' }}>
                    <label>Role:</label>
                    <Input
                        value={currentUser?.role || ''}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                role: e.target.value,
                            }))
                        }
                    />
                </div>
                <div style={{ marginBottom: '16px' }}>
                    <label>Sudo Name:</label>
                    <Input
                        value={currentUser?.sudoName || ''}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                sudoName: e.target.value,
                            }))
                        }
                    />
                </div>
                {/* <div style={{ marginBottom: '16px' }}>
                    <label>Password:</label>
                    <Input
                        value={currentUser?.password || ''}
                        onChange={(e) =>
                            setCurrentUser((prev) => ({
                                ...prev,
                                password: e.target.value,
                            }))
                        }
                    />
                </div> */}

            </Modal>
        </div>
    )
}
export default UserList;