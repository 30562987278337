import * as actionTypes from "./EmailSettingsTypes.js";

const INITIAL_STATE = {
    data: [],
    isLoading: false,
    emailData: null,
    isLoadingEmailData: false
};

const EmailSettingsReducer = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.ADD_EMAIL_LOADING:
            return {
                ...state,
                isLoading: true
            }
        //Department Data
        case actionTypes.ADD_EMAIL_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false
            }
        case actionTypes.ADD_EMAIL_FALED:
            return {
                ...state,
                isLoading: false
            }
        case actionTypes.GET_EMAIL_LOADING:
            return {
                ...state,
                isLoadingEmailData: true
            }
        //Department Data
        case actionTypes.GET_EMAIL_SUCCESS:
            return {
                ...state,
                emailData: payload,
                isLoadingEmailData: false
            }
        case actionTypes.GET_EMAIL_FALED:
            return {
                ...state,
                isLoadingEmailData: false
            }

        default:
            return state;
    }
}

export default EmailSettingsReducer;