import * as actionTypes from "./EmailSettingsTypes.js";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import { message } from "antd";

export const storeEmailSettings = (data, token, onSuccess) => {
    // console.log(data,"storeEmailSettings",token)
    return (dispatch) => {
        dispatch({
            type: actionTypes.ADD_EMAIL_LOADING,
        });
        Post('/agents/user/add-smtp-setting', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.ADD_EMAIL_SUCCESS,
                        payload: res?.data
                    });
                    onSuccess(res.message);
                } else {
                    dispatch({ type: actionTypes.ADD_EMAIL_FALED });
                    message.error(`${res.message}`);                   
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.ADD_EMAIL_FALED });
                message.error(err.message);
            })
    }
}

export const getEmailSettings = (data, token) => {

    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_EMAIL_LOADING,
        });
        Post('/agents/user/get-smtp-setting', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.GET_EMAIL_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.GET_EMAIL_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.GET_EMAIL_FALED });
            })
    }
}
