import React from "react";
import { Modal } from "antd";

const PrivacyPolicyModal = ({ isVisible, onClose }) => {
    return (
        <Modal
            title="Privacy Policy"
            open={isVisible}
            onCancel={onClose}
            footer={false}
            width={1000}
        >
            <ol style={{ listStyleType: 'Roman numbers', overflowY: 'scroll', height: '620px' }} >
                <li><h4>Introduction</h4>
                    <p>
                        Welcome to ConvoBird. We are committed to protecting your privacy and
                        ensuring that your personal information is handled in a safe and
                        responsible manner. This Privacy Policy outlines the types of
                        information we collect, how we use it, and the measures we take to
                        protect it.
                    </p>
                </li>
                <li><h4>Information We Collect</h4>
                    <p>We collect the following types of information from users of our chat
                        application:</p>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>
                            <h4>Personal Information:</h4>
                            <ul>
                                <li><b>Registration Information:</b> When you register, we may collect your
                                    name, email address, phone number, and other contact details.</li>
                                <li><b>Profile Information:</b> You may choose to provide additional
                                    information such as a profile picture, status messages, and other
                                    personal details.</li>
                            </ul>
                        </li>
                        <li>
                            <h4>Communication Data:</h4>
                            <ul>
                                <li><b>Messages:</b> We collect and store the messages you send and receive
                                    through our chat application.</li>
                                <li><b>Media Files:</b> Photos, videos, and other media files you share in
                                    chats are also collected and stored.</li>
                            </ul>
                        </li>
                        <li>
                            <h4>Usage Data:</h4>
                            <ul>
                                <li><b>Log Data:</b> We collect information about your use of the
                                    chat application, including IP addresses, browser types, operating
                                    systems, and usage patterns.</li>
                            </ul>
                        </li>
                        <li>
                            <h4>Device Information:</h4>
                            <ul>
                                <li><b>Device Details:</b> We may collect information about the
                                    device you use to access our chat application, including device
                                    type, operating system, and unique device identifiers.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>How We Use Your Information</h4>
                    <p>We use the collected information for the following purposes:</p>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>
                            <b>Providing Services:</b> To provide, maintain, and improve our chat
                            application and services.
                        </li>
                        <li>
                            <b>Personalization:</b> To personalize your experience and show
                            content that is more relevant to you.
                        </li>
                        <li>
                            <b>Communication:</b> To communicate with you, including sending
                            service-related announcements and updates.
                        </li>
                        <li>
                            <b>Security:</b> To enhance the security of our chat application and
                            protect against unauthorized access or use.
                        </li>
                        <li>
                            <b>Analytics:</b> To analyze usage trends and gather insights to
                            improve our chat application.
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Sharing Your Information</h4>
                    <p>We do not sell or rent your personal information to third parties. We
                        may share your information with:</p>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>
                            <b>Service Providers:</b> Trusted third-party service providers who
                            assist us in operating our chat application and providing services
                        </li>
                        <li>
                            <b>Legal Requirements:</b> Law enforcement or other governmental
                            agencies when required by law or to protect our legal rights.
                        </li>
                        <li>
                            <b>Business Transfers:</b> In the event of a merger, acquisition, or
                            sale of all or a portion of our assets, your information may be
                            transferred.
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Data Security</h4>
                    <p>We implement appropriate technical and organizational measures to
                        protect your personal information from unauthorized access, disclosure,
                        alteration, or destruction. However, no data transmission over the
                        internet or electronic storage is completely secure, and we cannot
                        guarantee absolute security</p>
                </li>
                <li>
                    <h4>Data Retention</h4>
                    <p>We retain your personal information for as long as necessary to provide
                        our services and fulfill the purposes outlined in this Privacy Policy.
                        We may also retain and use your information to comply with legal
                        obligations, resolve disputes, and enforce our agreements.</p>
                </li>
                <li>
                    <h4>Your Rights</h4>
                    <p>You have the right to:</p>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>
                            <b>Access:</b> Request access to the personal information we hold
                            about you.
                        </li>
                        <li>
                            <b>Correction:</b> Request correction of any inaccurate or incomplete
                            information.
                        </li>
                        <li>
                            <b>Deletion:</b>Request deletion of your personal information, subject
                            to certain legal obligations.
                        </li>
                        <li>
                            <b>Restriction:</b>Request restriction of processing your personal
                            information under certain circumstances.
                        </li>
                        <li>
                            <b>Objection:</b>Object to the processing of your personal information
                            for certain purposes.
                        </li>
                    </ol>
                    <p>To exercise these rights, please contact us at
                        contact@convobird.com</p>
                </li>
                <li>
                    <h4>Changes to This Privacy Policy</h4>
                    <p>We may update this Privacy Policy from time to time. We will notify you
                        of any changes by posting the new Privacy Policy on our website and
                        updating the effective date. Your continued use of our chat application
                        after the changes take effect constitutes your acceptance of the revised
                        Privacy Policy.</p>
                </li>
                <li>
                    <h4>Contact Us</h4>
                    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                    <ul>
                        <li>ConvoBird</li>
                        <li>Address:</li>
                        <li>Email Address: contact@convobird.com</li>
                        <li>Phone Number:</li>
                    </ul>
                </li>
            </ol>
        </Modal>
    );
};

export default PrivacyPolicyModal;
