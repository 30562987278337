import React from "react";
import { Col, Form, Input, Row, Space, Tabs, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { countries } from "../../helpers/countries";

const UserTab = ({ selectedData }) => {
  let findcountry = countries?.filter((i) => i?.cca2 == selectedData?.location?.country);
  let counts = selectedData?.url?.reduce((acc, obj) => {
    let url = obj?.url;
    if (acc[url]) {
      acc[url]++;
    } else {
      acc[url] = 1;
    }
    return acc;
  }, {});
  let result = Object?.entries(counts).map(([url, count]) => ({
    url: url,
    count: count
  }));
  return (
    <>
      <div className="userDetails">
        <div className="visitorInfo">
          <div style={{ marginBottom: 10 }} className="visitorName">
            <UserOutlined />
            <div>
              {/* <p>ID : #[{selectedData?.unique_id}]</p> */}
              <p>NAME : {selectedData?.name}  [{selectedData?.unique_id}] </p>
              <p>EMAIL : {selectedData?.email}</p>
              <p>PHONE : {selectedData?.phone}</p>
            </div>
          </div>
          <div className="visitorNum">
            <Form initialValues={{ number: selectedData?.phone, Note: selectedData?.visitor_notes }}>
              {/* <Form.Item name="number">
                <Input
                  disabled
                  name="number"
                  // value={selectedData?.phone}
                  type="number"
                  placeholder="Phone Number"
                />
              </Form.Item> */}
              <Form.Item name="Note">
                <TextArea
                  disabled
                  name="Note"
                  rows={4}
                  placeholder="Notes"
                  maxLength={6}
                />
              </Form.Item>
            </Form>
            <div className="visitorCount">
              <div>
                <h3 style={{ padding: '20px' }}>{selectedData?.visits}</h3>
                <p>Past Visitors</p>
              </div>
              <div>
                <h3 style={{ padding: '20px' }}>{selectedData?.past_conversationId?.length > 0 ? `${selectedData?.past_conversationId?.length}` : 0}</h3>
                <p>Past Chats</p>
              </div>
            </div>
          </div>
        </div>
        <div className="visitorInfo">
          <div className="visitorNum">
            <div style={{ paddingBottom: 20, display: "flex", flexDirection: "column" }} className="visitorPath">
              <h3>Visitor Path</h3>
              {result?.map((u, index) => {
                return <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <a style={{ wordBreak: "break-all" }} target={"_blank"} href={u?.url} key={index}>{u?.url}</a>&nbsp; &nbsp; <p>({u?.count})</p>
                </div>
              })}
            </div>
            <div className="visitorPath">
              <p>Referrer Url : {selectedData?.referrerUrl ? selectedData?.referrerUrl : "-"}</p>
              <p>Location : {selectedData?.location?.city && `${selectedData?.location?.city},`}{selectedData?.location?.region && `${selectedData?.location?.region},`}{findcountry?.[0]?.name} {findcountry?.[0]?.flag}</p>
              <p>Browser : {selectedData?.data?.browser}</p>
              <p>Platform : {selectedData?.data?.platform}</p>
              <p>Device : {selectedData?.data?.isMobileDevice ? "Mobile" : selectedData?.data?.isTabletdDevice ? "Tablet" : "Pc"}</p>
              <p>
                Details :{selectedData?.data?.userAgent}
              </p>
              <p>
                IP Address :{selectedData?.ip}
              </p>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default UserTab;
