import React, { useState } from 'react';
import { Button, Modal, Checkbox, Form, Input } from 'antd';


const AddModal = ({ isModalOpen, setIsModalOpen }) => {
    const [isChecked, setIsChecked] = useState(true)
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        // let newData = convertInputToWorkingDaysAndHours(values?.name);
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // function convertInputToWorkingDaysAndHours(input) {
    //     const hoursPerDay = 8; // Working hours in a day

    //     // Handle hours-only format (e.g., "25h")
    //     const hoursOnlyMatch = input.match(/^(\d+)h$/);
    //     if (hoursOnlyMatch) {
    //         const totalHours = parseInt(hoursOnlyMatch[1], 10);

    //         // Convert hours to working days and remaining hours
    //         const workingDays = Math.floor(totalHours / hoursPerDay);
    //         const remainingHours = totalHours % hoursPerDay;

    //         let result = "";
    //         if (workingDays > 0) {
    //             result += `${workingDays}d`;
    //         }
    //         if (remainingHours > 0) {
    //             if (result) result += ", ";
    //             result += `${remainingHours}h`;
    //         }

    //         return result || "0h"; // Fallback if no valid days or hours
    //     }

    //     // Handle combined formats like "2d 5h" or "1w 2d 6h"
    //     const combinedMatch = input.match(/^([\d\w\s]+)$/); // Matches any alphanumeric combo
    //     if (combinedMatch) {
    //         // Ensure consistent formatting by adding commas
    //         const formattedInput = input
    //             .replace(/\s+/g, " ") // Remove extra spaces
    //             .replace(/(\d+w|\d+d|\d+h)/g, "$1,") // Add commas after valid units
    //             .replace(/,\s*$/, ""); // Remove trailing comma
    //         return formattedInput;
    //     }

    //     // If no valid format matches, return error
    //     throw new Error("Invalid input format. Use formats like '9h', '25h', or '1w,2d,6h'.");
    // }

    return (
        <>
            <Modal footer={null} title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Feature Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Feature Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="hasValue" valuePropName="checked" label={null}>
                            <Checkbox onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} >Value</Checkbox>
                        </Form.Item>

                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Value!',
                                },
                            ]}
                        >
                            <Input disabled={!isChecked} />
                        </Form.Item>
                        <Form.Item label={null}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>
    );
};
export default AddModal;