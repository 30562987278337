import React, { useState, useEffect, useContext } from 'react';
import {
    Radio,
    Modal,
    Col,
    Row,
    Space,
    Button,
    Dropdown,
} from "antd";
import SideBarDrawer from './sideBarDrawer';
import {
    PicLeftOutlined, PicCenterOutlined, LogoutOutlined, DownOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from '../../context/socketContext';
import DrageCurrentChat from './drageCurrentChat';
import DragPastChat from './dragPastChat';
import * as actionTypes from "../../redux/Clients/ClientsTypes";
import EmailModal from '../emailModal/EmailModal';
import TransferModal from '../transferModal/TransferModal';
import BlockModal from '../blockModal/BlockModal';
import { arrayBufferToBase64 } from '../../helpers/fileChanger';
import { saveVisitorServed } from '../../redux/VisitorsJoined/VisitorsJoinedActions';

export default function DrageableBody({ visitorIndex, setvisitorIndex, currentVisitor }) {
    const servedData = useSelector((state) => state.VisitorReducer.data);
    const [messages, setMessages] = useState([]);
    const [isAnimation, setisAnimation] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Socket } = useContext(SocketContext);
    const [currentDisconnect, setcurrentDisconnect] = useState(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [transferModalOpen, setTransferModalOpen] = useState(false);
    const usertoken = useSelector((state) => state.Auth.token);
    const [One_to_One, setOne_to_One] = useState(null);
    // const [socketServed, setsocketServed] = useState(null);
    const [size, setSize] = useState("1");
    const [isShowSideBar, setisShowSiderBar] = useState(false);
    const [Loading, setLoading] = useState(false);
    const onChange = (e) => {
        setSize(e.target.value);
    };
    //Agennt Join Chat
    useEffect(() => {
        if (!Socket) return;
        Socket.on("agentJoinedChat", (data) => {
            if (currentVisitor?.unique_id == data?.unique_id) {
                setMessages(data?.allupdatedMessage);
                setisAnimation(false);
            }
        });

        Socket.on("agentLeave", (data) => {
            if (currentVisitor?.unique_id == data?.unique_id) {
                setMessages(data?.updatedMessages);
                setisAnimation(false);
            }
        });

        Socket.on("one_to_one_chat", (data) => {
            setOne_to_One(data);
            setisAnimation(true);
        });

        Socket?.on("userLeft", (id) => {
            setcurrentDisconnect(id);
        });
    }, [Socket]);

    useEffect(() => {
        if (One_to_One !== null) {
            if (currentVisitor?.conversationId == One_to_One?.conversationId) {
                let newfile = One_to_One?.file !== null ? One_to_One?.file : null
                if (newfile !== null) {
                    newfile.body = arrayBufferToBase64(newfile?.body)
                };
                setMessages((prev) => [
                    ...prev,
                    {
                        file: newfile,
                        message: One_to_One.message,
                        name: One_to_One?.senderID,
                        senderID: One_to_One?.senderID,
                        to: One_to_One?.to,
                        uniqueMessageID: One_to_One?.uniqueMessageID,
                        time: One_to_One?.time,
                        type: One_to_One?.type,
                    },
                ]);
                if (currentVisitor?.minimized) {
                    One_to_One.status = "delivered";
                    Socket.emit("visitorMsgToServer", One_to_One);
                }
                else {
                    One_to_One.status = "seen";
                    Socket.emit("visitorMsgToServer", One_to_One);
                }
            }
            setOne_to_One(null);
        }
    }, [One_to_One]);


    // useEffect(() => {
    //     let observer = new ResizeObserver(entries => {
    //         for (let entry of entries) {
    //             let currentHeight = parseFloat(entry.target.style.width);
    //             setcurrentWith(currentHeight);
    //         }
    //     });
    //     let elements = document.getElementsByClassName(currentVisitor?.unique_id);
    //     if (elements) {
    //         for (let i = 0; i < elements.length; i++) {
    //             observer.observe(elements[i]);
    //         }
    //     }
    //     return () => {
    //         observer.disconnect();
    //     };
    // }, []);

    const items = [
        {
            label: (
                <div onClick={() => BlockVisitor()}>
                    <span>Block</span>
                </div>
            ),
            key: "0",
        },

        currentVisitor?.conversationId && currentVisitor?.servedBy?.some((x) => x?.id == userData?._id) && {
            label: (
                <div onClick={() => setTransferModalOpen(true)}>
                    <span>Transfer Chat to Agent</span>
                </div>
            ),
            key: "1",
        },
        {
            label: (
                <div onClick={() => exportToMail()}>
                    <span>Export Chat to mail</span>
                </div>
            ),
            key: "2",
        },
    ];
    const exportToMail = () => {
        setIsEmailModalOpen(true);
    };

    const BlockVisitor = () => {
        setIsModalOpen(true);
    };

    const ontogglebtn = () => {
        setisShowSiderBar(!isShowSideBar);
        if (visitorIndex?.includes(currentVisitor?.unique_id)) {
            let findIndex = visitorIndex?.filter((x) => x !== currentVisitor?.unique_id);
            setvisitorIndex(findIndex);
        } else {
            setvisitorIndex((pre) => [...pre, currentVisitor?.unique_id])
        }
    };

    useEffect(() => {
        if (visitorIndex?.includes(currentVisitor?.unique_id)) {
            setisShowSiderBar(true)
        }
    }, [currentVisitor]);
    return (
        <>
            <TransferModal SelectedVisitorData={currentVisitor} setTransferModalOpen={setTransferModalOpen} transferModalOpen={transferModalOpen} />
            <BlockModal
                userData={userData}
                vistiorData={currentVisitor}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                Socket={Socket} />
            <EmailModal
                userData={userData}
                vistiorData={currentVisitor}
                setIsEmailModalOpen={setIsEmailModalOpen}
                isEmailModalOpen={isEmailModalOpen}
                usertoken={usertoken} />
            <div style={{ padding: 5, }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px" }}>
                    <div>
                        <Radio.Group
                            // size="small"
                            value={size}
                            onChange={onChange}
                            style={{
                            }}
                        >
                            <Radio.Button value="1">Current Chat</Radio.Button>
                            <Radio.Button value="2">{`Past Chat ${currentVisitor?.past_conversationId?.length > 0 ? `(${currentVisitor?.past_conversationId?.length})` : ""}`}</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontSize: "21px", zIndex: 1, cursor: "pointer" }} >

                        <div onClick={() => ontogglebtn()} style={{ paddingRight: 10, paddingTop: 5, color: '#355e64' }}>
                            {isShowSideBar ? <PicCenterOutlined /> : <PicLeftOutlined />}
                        </div>
                        <div style={{ zIndex: 999, paddingTop: 5 }}>

                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={["click"]}
                                overlayClassName='Actions_visitors'
                            >
                                <Button
                                    // style={{ marginRight: 10 }}
                                    onClick={(e) => e.preventDefault()}
                                    style={{ width: 123, marginRight: '8px' }}
                                >
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>

                </div>
                <div style={{ width: "100%", padding: "10px 2px 10px 10px", display: "flex", justifyContent: "space-between", }} className="header">
                    <div className={isShowSideBar ? "mainDragViewclose" : "mainDragViewopen"}>
                        <div className={isShowSideBar ? "showMainDrag" : "hideMainDrag"}>
                            {size == 1 ? (
                                <div style={{ height: "100%" }}>
                                    <DrageCurrentChat
                                        isAnimation={isAnimation} setisAnimation={setisAnimation}
                                        Loading={Loading} setLoading={setLoading}
                                        messages={messages}
                                        setMessages={setMessages}
                                        currentActiveVisitorData={currentVisitor}
                                    />
                                </div>
                            ) : (
                                <div style={{ height: "100%" }}>
                                    <DragPastChat currentActiveVisitorData={currentVisitor} />
                                </div>
                            )
                            }
                        </div>

                    </div>

                    <div className={isShowSideBar ? "showSideBarDrag" : "hideSideBarDrag"}>
                        <SideBarDrawer currentActiveVisitorData={currentVisitor} isShow={isShowSideBar} setisShow={setisShowSiderBar} />
                    </div>


                </div>
            </div >
        </>

    )
}
