import React from "react";
import { Modal } from "antd";

const TermsModal = ({ isVisible, onClose }) => {
    return (
        <Modal
            title="Terms Of Services"
            open={isVisible}
            onCancel={onClose}
            footer={false}
            width={1000}
        >
            <ol style={{ listStyleType: 'Roman numbers', overflowY: 'scroll', height: '620px' }} >
                <li>
                    <h4>Acceptance of Terms</h4>
                    <p>
                        By accessing or using ConvoBird, you agree to comply with and be bound by these Terms & Conditions ("Terms"). If you do not agree to these Terms, you may not use the Service.
                    </p>
                </li>
                <li>
                    <h4>Eligibility</h4>
                    <p>
                        You must be at least 13 years old to use our Service. By using the Service, you represent and warrant that you meet this age requirement.
                    </p>
                </li>
                <li>
                    <h4>User Accounts</h4>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li><b>Registration:</b> To use certain features of the Service, you may need to create an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and complete.</li>
                        <li><b>Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>
                    </ol>
                </li>
                <li>
                    <h4>User Conduct</h4>
                    <p>
                        You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to:
                    </p>
                    <ul>
                        <li>Post, share, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable.</li>
                        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
                        <li>Engage in any activity that interferes with or disrupts the Service or the servers and networks connected to the Service.</li>
                        <li>Use any automated means to access the Service, including scripts, bots, or other similar applications.</li>
                    </ul>
                </li>
                <li>
                    <h4>Content</h4>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li><b>User Content:</b> You retain ownership of any content you post, share, or transmit through the Service ("User Content"). By providing User Content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content.</li>
                        <li><b>Prohibited Content:</b> We reserve the right to remove any User Content that violates these Terms or is otherwise objectionable, without prior notice.</li>
                    </ol>
                </li>
                <li>
                    <h4>Privacy</h4>
                    <p>Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices regarding your personal information</p>
                </li>
                <li>
                    <h4>Intellectual Property</h4>
                    <p>The Service and its original content, features, and functionality are and will remain the exclusive property of ConvoBird and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
                </li>
                <li>
                    <h4>Termination</h4>
                    <p>We may terminate or suspend your account and access to the Service, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Service will immediately cease.</p></li>
                <li>
                    <h4>Disclaimers and Limitation of Liability</h4>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li><p>Disclaimers: The Service is provided on an "AS IS" and "AS AVAILABLE" basis. We make no warranties, express or implied, regarding the Service, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</p></li>
                        <li><p>Limitation of Liability: To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, Trojan horses, or the like that may be transmitted to or through the Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p></li>
                    </ol>
                </li>
                <li>
                    <h4>Governing Law</h4>
                    <p>These Terms shall be governed and construed in accordance with the laws of New York / U.S.A, without regard to its conflict of law provisions.</p>
                </li>
                <li>
                    <h4>Changes to Terms</h4>
                    <p>We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on our website and updating the effective date. Your continued use of the Service after the changes take effect constitutes your acceptance of the revised Terms.</p>
                </li>
                <li><h4>Contact Us</h4>
                    <ul>
                        <li>ConvoBird</li>
                        <li>Address:</li>
                        <li>Email Address: contact@convobird.com</li>
                        <li>Phone Number:</li>
                    </ul>
                </li>
            </ol>
        </Modal>
    );
};

export default TermsModal;
