import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Col, Row, Spin, message, Modal } from "antd";
import { UserOutlined, LoadingOutlined, ToolOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { get_Plan_Types, Login_Action } from "../../../redux/Auth/AuthActions";
import loginBack from "../../../assets/image/login-back.png";
import getStart from "../../../assets/image/getStart.png";
import logo from "../../../assets/image/main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import TermsModal from "./TermsModal ";
import PrivacyPolicyModal from "./PrivacyPolicyModal ";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [rememberMe, setrememberMe] = React.useState(true);
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const onFinish = (values) => {
    let data = {
      email: values?.email,
      password: values?.password,
    };
    dispatch(Login_Action(data, error, rememberMe, navigate));
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#ffffff",
        margin: "0px 25px",
      }}
      spin
    />
  );

  const error = (err) => {
    messageApi.open({
      type: "error",
      content: err,
    });
  };
  // Modal
  const showTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);
  const showPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);

  const isModalOpen = () => isModalOpen(true);
  return (
    <>
      {contextHolder}
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="loginForm"
        style={{ minHeight: "100vh", backgroundImage: `url(${loginBack})` }}
      >
        <Col span={4} className="sideBlue">
          <img src={getStart} />
        </Col>
        <Col span={6} className="formSide">
          <img src={logo} />
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            autoComplete="on"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item

              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
              label={
                <span>
                  <UserOutlined /> Email Address
                </span>
              }
              className="loginLabel"
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              label={
                <span>
                  <ToolOutlined /> Password
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
              className="loginLabel"
            >
              <Input.Password
                style={{
                  outline: '0',
                  border: 'none',
                  width: '110%',

                }}
                placeholder="Password"
              />
            </Form.Item>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox checked={rememberMe} onChange={(e) => setrememberMe(e.target.checked)} >Remember me</Checkbox>
              </Form.Item>
              <a onClick={() => navigate("/forget-password")} className="login-form-forgot" >
                Forgot password
              </a>
            </div>
            <Form.Item>
              <Button
                disabled={isLoading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Login Now"}
              </Button>
            </Form.Item>
          </Form>
          {/* <p style={{ fontSize: 13, textAlign: "left", margin: "0px 15px" }}>Don't have an account? <Link to={"/signup"} className="linkto">Create a free account</Link></p> */}
          <p className="terms">
            By clicking Log In. I confirm that I have read and agree to the
            Userthemes <span onClick={showTermsModal} style={{ cursor: "pointer", textDecoration: "none" }}>
              Terms & Conditions
            </span> &  <span onClick={showPrivacyModal} style={{ cursor: "pointer", textDecoration: "none" }}>
              Privacy Policy
            </span> and to receive email and updates.
          </p>
        </Col>
      </Row>
      <TermsModal isVisible={isTermsModalOpen} onClose={closeTermsModal} />
      <PrivacyPolicyModal isVisible={isPrivacyModalOpen} onClose={closePrivacyModal} />
    </>
  );
};

export default LoginPage;
