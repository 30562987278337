import React from 'react'

const packageDetail = ({ companiesDetails }) => {
    return (
        <div className='pagiTable packageTable'>
            <h2>Package Details</h2>
            {companiesDetails?.package?.map((pkg, index) => (
                <div key={index}>
                    <ul>
                        <li>Title: <span> {pkg.title} </span></li>
                        <li>Description: <span>{pkg.description}</span></li>
                        <li>Price: <span>{pkg.price}</span></li>
                        <li>SKU: <span>{pkg.sku}</span></li>
                        <li>Users: <span>{pkg.userCount}</span></li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                        <li>9 </li>
                        <li>10</li>
                        <li>11</li>
                        <li>12</li>
                    </ul>
                </div>
            ))}
        </div >
    )
}
export default packageDetail;