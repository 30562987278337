import React, { useEffect, useState } from 'react'

import {
    Button,
    Tabs,
    Form,
    Input,
    placeholder,
    Col,
    Row,
    message,
    Spin,
    Select
} from "antd";


import { useDispatch, useSelector } from "react-redux";
import { storeEmailSettings, getEmailSettings } from '../../redux/EmailSettings/EmailSettingsActions';

export default function EmailSettings() {
    const usertoken = useSelector((state) => state.Auth.token);
    const data = useSelector((state) => state.EmailSettingsReducer.data);
    const emailData = useSelector((state) => state.EmailSettingsReducer.emailData);
    const userData = useSelector((state) => state.Auth.userData);
    const isLoading = useSelector((state) => state.EmailSettingsReducer.isLoading);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [user_name, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [from, setFrom] = useState("");
    const [from_email, setFromEmail] = useState("");




    const onsubmit = () => {

        const data = {
            company_id: userData?.companyId,
            host: host,
            port: port,
            user_name: user_name,
            pass: password,
            from: from,
            from_email: from_email
        }
        dispatch(storeEmailSettings(data, usertoken, onSuccess));
    }

    const onSuccess = (msg) => {
        if (msg) {
            message.success(`${msg}`);
        }
    };

    useEffect(() => {
        const data = { company_id: userData?.companyId }
        dispatch(getEmailSettings(data, usertoken));
    }, [usertoken]);


    useEffect(() => {
        if (emailData !== null) {

            form.setFieldsValue({
                host: emailData?.host,
                from: emailData?.from,
                from_email: emailData?.from_email,
                password: emailData?.pass,
                port: emailData?.port,
                user_name: emailData?.user_name
            });
            setHost(emailData?.host);
            setPort(emailData?.port);
            setUserName(emailData?.user_name);
            setPassword(emailData?.pass);
            setFromEmail(emailData?.from_email);
            setFrom(emailData?.from);
        }


    }, [emailData])

    return (
        <div className="profileTabs">
            <Row className="addShortcut">
                <Col span={12}>
                    <h6>Email Settings</h6>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        //   onFinish={onFinish}
                        //   onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >

                        <Form.Item label="Host" name="host">
                            <Input value={host} onChange={(e) => setHost(e.target.value)} />
                        </Form.Item>
                        <Form.Item label="Port" name="port">
                            <Input value={port} onChange={(e) => setPort(e.target.value)} />
                        </Form.Item>

                        <Form.Item label="User Name" name="user_name">
                            <Input value={user_name} onChange={(e) => setUserName(e.target.value)} />
                        </Form.Item>

                        <Form.Item label="Password" name="password">
                            <Input value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Item>

                        <Form.Item label="from" name="from">
                            <Input value={from} onChange={(e) => setFrom(e.target.value)} />
                        </Form.Item>

                        <Form.Item label="From Email" name="from_email">
                            <Input value={from_email} onChange={(e) => setFromEmail(e.target.value)} />
                        </Form.Item>

                        {/* <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item> */}


                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button onClick={() => onsubmit()} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
