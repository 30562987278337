import { Space, Switch, Table } from 'antd';
import React, { useState } from 'react';

const BrandList = ({ companiesDetails }) => {
    const [brands, setBrands] = useState(
        companiesDetails?.brands?.map((brand, index) => ({
            key: index,
            name: brand.brandname,
            isActive: brand.isActive,
        })) || []
    );

    const onChange = (checked, record) => {
        console.log(`Switch for ${record.name} toggled to ${checked}`);
        setBrands((prevBrands) =>
            prevBrands.map((brand) =>
                brand.key === record.key
                    ? { ...brand, isActive: checked }
                    : brand
            )
        );
    };

    const columns = [
        {
            title: 'Brand Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Switch size="small"
                    checked={record.isActive}
                    onChange={(checked) => onChange(checked, record)} />
            ),
        },
    ];

    return (
        <div className="pagiTable">
            <h2>Brand List</h2>
            <Table columns={columns} dataSource={brands} pagination={false} />
        </div>
    );
};

export default BrandList;
