import React, { useState } from "react";

function ClipboardCopy() {
  const [copied, setCopied] = useState(false);
  const [textToCopy, setTextToCopy] = useState(`
  <script src="https://cdnnew.convobird.com/media/index.js" token="<Your Brand Token Here>"></script>`);

  const copyToClipboard = () => {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
  };

  const handleDivClick = () => {
    copyToClipboard();
  };

  return (
    <div className="clipboard">
      <div className="copiedArea" onClick={handleDivClick}>
        {textToCopy}
      </div>
      {copied && (
        <div>
          <div className="copiedText">Copied to clipboard!</div>
        </div>
      )}
    </div>
  );
}

export default ClipboardCopy;